<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="editedItem.Navn"
        label="Avdelingsnavn"
        maxlength="42"
      ></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import KeyValue from "../global/KeyValue";

export default {
  extends: KeyValue,
  name: "dlgAvdeling",
  components: {},

  model: {
    prop: "editedItemIn",
    event: "input",
  },

  props: {
    keyFirmaID: Number,
    editedItemIn: Object,
    editMode: Number, //v-if="editMode !== -1" - Not in "new"
  },

  computed: {
    editedItem() {
      return this.editedItemIn
    }
  },

  methods: {
    output: function () {
      // pass back to the parent
      this.$emit("input", this.editedItem);
    },
  },
};
</script>
